export default [
  {
    Municipality: "Δήμος Αθηναίων",
    BoundingBox: ["37.9488434", "38.03276", "23.6870046", "23.7904386"],
  },
  {
    Municipality: "Δήμος Θεσσαλονίκης",
    BoundingBox: ["40.5856783", "40.6531483", "22.8994639", "22.989905"],
  },
  {
    Municipality: "Δήμος Πατρέων",
    BoundingBox: ["38.1148839", "38.341405", "21.6031725", "21.9409224"],
  },
  {
    Municipality: "Δήμος Ηρακλείου",
    BoundingBox: ["35.1484188", "35.352264", "24.9452999", "25.2052619"],
  },
  {
    Municipality: "Δήμος Πειραιά",
    BoundingBox: ["37.9490166", "37.9491166", "23.6433656", "23.6434656"],
  },
  {
    Municipality: "Δήμος Λάρισας",
    BoundingBox: ["36.4294786", "36.4302551", "28.2149271", "28.2159637"],
  },
  {
    Municipality: "Δήμος Βόλου",
    BoundingBox: ["39.2292026", "39.4633959", "22.6711862", "23.1053493"],
  },
  {
    Municipality: "Δήμος Περιστερίου",
    BoundingBox: ["37.9962245", "38.0350762", "23.6658899", "23.7183025"],
  },
  {
    Municipality: "Δήμος Ρόδου",
    BoundingBox: ["35.876662", "36.4584362", "27.683572", "28.2476283"],
  },
  {
    Municipality: "Δήμος Ιωαννιτών",
    BoundingBox: ["39.4990191", "39.7825501", "20.7231198", "21.079626"],
  },
  {
    Municipality: "Δήμος Χανίων",
    BoundingBox: ["35.3296583", "35.6020112", "23.9097686", "24.2018404"],
  },
  {
    Municipality: "Δήμος Αβδήρων",
    BoundingBox: ["40.94514", "41.14514", "24.89297", "25.09297"],
  },
  {
    Municipality: "Δήμος Αγαθονησίου",
    BoundingBox: ["37.4256035", "37.4871519", "26.9253612", "27.0100091"],
  },
  {
    Municipality: "Δήμος Αγιάς",
    BoundingBox: ["39.5120536", "39.9283595", "22.5789336", "22.9160435"],
  },
  {
    Municipality: "Δήμος Αγίας Βαρβάρας",
    BoundingBox: ["37.9829209", "37.9994156", "23.6466679", "23.668001"],
  },
  {
    Municipality: "Δήμος Αγίας Παρασκευής",
    BoundingBox: ["37.9852882", "38.0234919", "23.8052957", "23.847768"],
  },
  {
    Municipality: "Δήμος Αγίου Βασιλείου",
    BoundingBox: ["35.0021088", "35.2812939", "24.2844438", "24.7249127"],
  },
  {
    Municipality: "Δήμος Αγίου Δημητρίου",
    BoundingBox: ["37.9183734", "37.949812", "23.7143146", "23.744454"],
  },
  {
    Municipality: "Δήμος Αγίου Ευστρατίου",
    BoundingBox: ["39.4620388", "39.5662811", "24.9706737", "25.0631766"],
  },
  {
    Municipality: "Δήμος Αγίου Νικολάου",
    BoundingBox: ["35.0690377", "35.6007608", "25.490227", "25.7755542"],
  },
  {
    Municipality: "Δήμος Αγίων Αναργύρων - Καματερού",
    BoundingBox: ["38.0131522", "38.0667392", "23.6839185", "23.7347879"],
  },
  {
    Municipality: "Δήμος Αγκιστρίου",
    BoundingBox: ["37.6728988", "37.7263341", "23.235375", "23.3895251"],
  },
  {
    Municipality: "Δήμος Αγράφων",
    BoundingBox: ["38.8722074", "39.2567999", "21.3739324", "21.7789452"],
  },
  {
    Municipality: "Δήμος Αγρινίου",
    BoundingBox: ["38.4028385", "38.890969", "21.1938665", "21.6804609"],
  },
  {
    Municipality: "Δήμος Αιγάλεω",
    BoundingBox: ["37.9761673", "38.0093513", "23.6654604", "23.6976182"],
  },
  {
    Municipality: "Δήμος Αιγιαλείας",
    BoundingBox: ["37.9530384", "38.3285502", "21.8625606", "22.3780635"],
  },
  {
    Municipality: "Δήμος Αίγινας",
    BoundingBox: ["37.6123533", "37.8260221", "23.236577", "23.5652998"],
  },
  {
    Municipality: "Δήμος Ακτίου-Βόνιτσας",
    BoundingBox: ["38.6801598", "38.9580053", "20.7184668", "21.178787"],
  },
  {
    Municipality: "Δήμος Αλεξάνδρειας",
    BoundingBox: ["40.4515608", "40.7187591", "22.289187", "22.6815493"],
  },
  {
    Municipality: "Δήμος Αλεξανδρούπολης",
    BoundingBox: ["40.7273629", "41.1103122", "25.6180798", "26.3596787"],
  },
  {
    Municipality: "Δήμος Αλιάρτου",
    BoundingBox: ["38.024892", "38.0250706", "23.7464766", "23.7468866"],
  },
  {
    Municipality: "Δήμος Αλίμου",
    BoundingBox: ["37.8939013", "37.9281876", "23.6980611", "23.7444039"],
  },
  {
    Municipality: "Δήμος Αλμυρού",
    BoundingBox: ["38.9696438", "39.2860778", "22.4908031", "23.0671934"],
  },
  {
    Municipality: "Δήμος Αλμωπίας",
    BoundingBox: ["40.8504415", "41.1700073", "21.78846", "22.3319588"],
  },
  {
    Municipality: "Δήμος Αλοννήσου",
    BoundingBox: ["39.0321959", "39.5066696", "23.8074795", "24.3332979"],
  },
  {
    Municipality: "Δήμος Αμαρίου",
    BoundingBox: ["35.111553", "35.2963782", "24.5489309", "24.8013921"],
  },
  {
    Municipality: "Δήμος Αμαρουσίου",
    BoundingBox: ["38.0238541", "38.0671749", "23.7759271", "23.8314849"],
  },
  {
    Municipality: "Δήμος Αμοργού",
    BoundingBox: ["36.7670404", "36.938015", "25.6584533", "26.0861365"],
  },
  {
    Municipality: "Δήμος Αμπελοκήπων-Μενεμένης",
    BoundingBox: ["40.6400828", "40.6722087", "22.8612712", "22.9353197"],
  },
  {
    Municipality: "Δήμος Αμυνταίου",
    BoundingBox: ["40.522214", "40.8519223", "21.3797535", "21.822062"],
  },
  {
    Municipality: "Δήμος Αμφίκλειας-Ελάτειας",
    BoundingBox: ["38.5116052", "38.7485916", "22.4271796", "22.9313053"],
  },
  {
    Municipality: "Δήμος Αμφιλοχίας",
    BoundingBox: ["38.7539106", "39.174476", "21.0785459", "21.5116706"],
  },
  {
    Municipality: "Δήμος Αμφίπολης",
    BoundingBox: ["40.7573402", "41.0494182", "23.691966", "24.1179347"],
  },
  {
    Municipality: "Δήμος Ανατολικής Μάνης",
    BoundingBox: ["36.3852463", "36.8852416", "22.3313087", "22.5806248"],
  },
  {
    Municipality: "Δήμος Ανάφης",
    BoundingBox: ["36.2658442", "36.4050734", "25.727614", "25.8968464"],
  },
  {
    Municipality: "Δήμος Ανδραβίδας - Κυλλήνης",
    BoundingBox: ["37.8541048", "38.1042855", "21.1062122", "21.5457725"],
  },
  {
    Municipality: "Δήμος Ανδρίτσαινας - Κρεστένων",
    BoundingBox: ["37.3965395", "37.6455078", "21.5302312", "21.9993968"],
  },
  {
    Municipality: "Δήμος Άνδρου",
    BoundingBox: ["37.6821091", "37.9995793", "24.6804649", "25.0007636"],
  },
  {
    Municipality: "Δήμος Αντιπάρου",
    BoundingBox: ["36.9386386", "37.078176", "24.9529297", "25.0971741"],
  },
  {
    Municipality: "Δήμος Ανωγείων",
    BoundingBox: ["35.1741133", "35.3174618", "24.8126992", "24.9254558"],
  },
  {
    Municipality: "Δήμος Αποκορώνου",
    BoundingBox: ["35.2491972", "35.4755678", "24.0435415", "24.3319876"],
  },
  {
    Municipality: "Δήμος Αργιθέας",
    BoundingBox: ["39.1648319", "39.4154972", "21.3484962", "21.6416373"],
  },
  {
    Municipality: "Δήμος Άργους - Μυκηνών",
    BoundingBox: ["37.4504036", "37.8607841", "22.3910963", "22.9571773"],
  },
  {
    Municipality: "Δήμος Αριστοτέλη",
    BoundingBox: ["40.3071603", "40.6426162", "23.5084897", "24.0192402"],
  },
  {
    Municipality: "Δήμος Αρριανών",
    BoundingBox: ["41.0304452", "41.348113", "25.5051756", "25.9640217"],
  },
  {
    Municipality: "Δήμος Αρταίων",
    BoundingBox: ["39.0042512", "39.4111437", "20.7624908", "21.0557339"],
  },
  {
    Municipality: "Δήμος Αρχαίας Ολυμπίας",
    BoundingBox: ["37.585987", "37.9568204", "21.5342208", "21.8901953"],
  },
  {
    Municipality: "Δήμος Αρχανών - Αστερουσίων",
    BoundingBox: ["34.9359556", "35.2819054", "25.078754", "25.2854083"],
  },
  {
    Municipality: "Δήμος Ασπροπύργου",
    BoundingBox: ["38.0164734", "38.1741124", "23.5312023", "23.6562488"],
  },
  {
    Municipality: "Δήμος Αστυπάλαιας",
    BoundingBox: ["35.9240288", "36.6457162", "26.1312623", "26.7627869"],
  },
  {
    Municipality: "Δήμος Αχαρνών",
    BoundingBox: ["38.0498653", "38.2266528", "23.6718719", "23.8374097"],
  },
  {
    Municipality: "Δήμος Βάρης - Βούλας - Βουλιαγμένης",
    BoundingBox: ["37.7623384", "37.8751905", "23.7323533", "23.8424257"],
  },
  {
    Municipality: "Δήμος Βέλου-Βόχας",
    BoundingBox: ["37.8432756", "37.9991298", "22.6486136", "22.825166"],
  },
  {
    Municipality: "Δήμος Βέροιας",
    BoundingBox: ["40.3001832", "40.655142", "21.9279687", "22.4049198"],
  },
  {
    Municipality: "Δήμος Βιάννου",
    BoundingBox: ["34.9814097", "35.1306448", "25.3088424", "25.550441"],
  },
  {
    Municipality: "Δήμος Βισαλτίας",
    BoundingBox: ["40.7731937", "41.0182218", "23.2982685", "23.8444656"],
  },
  {
    Municipality: "Δήμος Βοΐου",
    BoundingBox: ["40.1453276", "40.4555734", "21.0643756", "21.6960431"],
  },
  {
    Municipality: "Δήμος Βόλβης",
    BoundingBox: ["40.5681655", "40.8428944", "23.1849636", "23.7649972"],
  },
  {
    Municipality: "Δήμος Βόρειας Κυνουρίας",
    BoundingBox: ["37.2119676", "37.5226248", "22.4352372", "22.8276457"],
  },
  {
    Municipality: "Δήμος Βορείων Τζουμέρκων",
    BoundingBox: ["39.3932969", "39.684168", "20.9175098", "21.2032772"],
  },
  {
    Municipality: "Δήμος Βριλησσίων",
    BoundingBox: ["38.0255122", "38.0525715", "23.8210513", "23.8544546"],
  },
  {
    Municipality: "Δήμος Βύρωνος",
    BoundingBox: ["41.1154849", "41.1176249", "25.3995673", "25.4002993"],
  },
  {
    Municipality: "Δήμος Γαλατσίου",
    BoundingBox: ["38.0044237", "38.0340993", "23.7432157", "23.7727149"],
  },
  {
    Municipality: "Δήμος Γαύδου",
    BoundingBox: ["34.8008924", "34.9401799", "23.9887185", "24.1326933"],
  },
  {
    Municipality: "Δήμος Γεωργίου Καραϊσκάκη",
    BoundingBox: ["39.1171609", "39.3412395", "21.0726589", "21.4300225"],
  },
  {
    Municipality: "Δήμος Γλυφάδας",
    BoundingBox: ["37.8533193", "37.9126401", "23.7269642", "23.8102785"],
  },
  {
    Municipality: "Δήμος Γόρτυνας",
    BoundingBox: ["34.9243497", "35.2087756", "24.8932243", "25.149125"],
  },
  {
    Municipality: "Δήμος Γορτυνίας",
    BoundingBox: ["37.4641301", "37.8657977", "21.7909191", "22.2379943"],
  },
  {
    Municipality: "Δήμος Γρεβενών",
    BoundingBox: ["39.830359", "40.2487707", "20.9565588", "21.7608519"],
  },
  {
    Municipality: "Δήμος Δάφνης - Υμηττού",
    BoundingBox: ["37.9419301", "37.9574213", "23.7245433", "23.7570512"],
  },
  {
    Municipality: "Δήμος Δέλτα",
    BoundingBox: ["40.5005039", "40.7292222", "22.6108007", "22.8900783"],
  },
  {
    Municipality: "Δήμος Δελφών",
    BoundingBox: ["38.2781313", "38.8075968", "22.0947859", "22.6138349"],
  },
  {
    Municipality: "Δήμος Δεσκάτης",
    BoundingBox: ["39.8459199", "40.1091396", "21.5350809", "21.9193323"],
  },
  {
    Municipality: "Δήμος Διδυμοτείχου",
    BoundingBox: ["41.3018647", "41.5149912", "26.1532069", "26.6355754"],
  },
  {
    Municipality: "Δήμος Διονύσου",
    BoundingBox: ["38.0699184", "38.1607951", "23.8217974", "23.9461594"],
  },
  {
    Municipality: "Δήμος Δίου-Ολύμπου",
    BoundingBox: ["39.9458971", "40.2579754", "22.3443345", "22.6788494"],
  },
  {
    Municipality: "Δήμος Διρφύων-Μεσσαπίων",
    BoundingBox: ["38.4618388", "38.7456424", "23.4362963", "23.9302148"],
  },
  {
    Municipality: "Δήμος Διστόμου-Αράχοβας-Αντίκυρας",
    BoundingBox: ["38.3490261", "38.5811288", "22.4874604", "22.7769518"],
  },
  {
    Municipality: "Δήμος Δομοκού",
    BoundingBox: ["38.9990245", "39.2732231", "22.0939942", "22.6034629"],
  },
  {
    Municipality: "Δήμος Δοξάτου",
    BoundingBox: ["40.967625", "41.1515616", "24.0979147", "24.4208292"],
  },
  {
    Municipality: "Δήμος Δράμας",
    BoundingBox: ["41.0572394", "41.5736675", "24.0462896", "24.4078253"],
  },
  {
    Municipality: "Δήμος Δυτικής Αχαΐας",
    BoundingBox: ["37.9126733", "38.2183559", "21.3506249", "21.7133572"],
  },
  {
    Municipality: "Δήμος Δυτικής Μάνης",
    BoundingBox: ["36.7378171", "37.0538206", "22.125686", "22.4029542"],
  },
  {
    Municipality: "Δήμος Δωδώνης",
    BoundingBox: ["39.2811096", "39.6251211", "20.5725245", "20.979008"],
  },
  {
    Municipality: "Δήμος Δωρίδος",
    BoundingBox: ["38.3202265", "38.7699457", "21.853229", "22.3257734"],
  },
  {
    Municipality: "Δήμος Έδεσσας",
    BoundingBox: ["40.6761401", "40.9100449", "21.704198", "22.1378527"],
  },
  {
    Municipality: "Δήμος Ελασσόνας",
    BoundingBox: ["39.6508973", "40.1966584", "21.8639177", "22.4959287"],
  },
  {
    Municipality: "Δήμος Ελαφονήσου",
    BoundingBox: ["36.4551882", "36.5318181", "22.9224264", "23.0087723"],
  },
  {
    Municipality: "Δήμος Ελευσίνας",
    BoundingBox: ["38.0243715", "38.136494", "23.4777326", "23.5685484"],
  },
  {
    Municipality: "Δήμος Ελληνικού-Αργυρούπολης",
    BoundingBox: ["37.8728485", "37.9229149", "23.7112737", "23.8061269"],
  },
  {
    Municipality: "Δήμος Εμμανουήλ Παππά",
    BoundingBox: ["40.9328284", "41.1928934", "23.5470614", "23.8281685"],
  },
  {
    Municipality: "Δήμος Εορδαίας",
    BoundingBox: ["40.3637708", "40.7080955", "21.469366", "21.9312873"],
  },
  {
    Municipality: "Δήμος Επιδαύρου",
    BoundingBox: ["37.5219839", "37.7298999", "22.892893", "23.2003462"],
  },
  {
    Municipality: "Δήμος Ερέτριας",
    BoundingBox: ["38.3790901", "38.5980756", "23.7235637", "23.9681788"],
  },
  {
    Municipality: "Δήμος Ερμιονίδας",
    BoundingBox: ["37.2851347", "37.5345775", "23.0431654", "23.454616"],
  },
  {
    Municipality: "Δήμος Ερυμάνθου",
    BoundingBox: ["37.8363322", "38.1696466", "21.5763976", "22.0153202"],
  },
  {
    Municipality: "Δήμος Ευρώτα",
    BoundingBox: ["36.785547", "37.1421347", "22.4637796", "22.9616708"],
  },
  {
    Municipality: "Δήμος Ζαγοράς-Μουρεσίου",
    BoundingBox: ["39.3476915", "39.5283338", "23.0034628", "23.2074493"],
  },
  {
    Municipality: "Δήμος Ζαγορίου",
    BoundingBox: ["39.7004907", "40.0293492", "20.6004911", "21.1205025"],
  },
  {
    Municipality: "Δήμος Ζακύνθου",
    BoundingBox: ["37.2399676", "37.9338691", "20.6171232", "21.0220861"],
  },
  {
    Municipality: "Δήμος Ζαχάρως",
    BoundingBox: ["37.3745799", "37.5620301", "21.5839094", "21.8684473"],
  },
  {
    Municipality: "Δήμος Ζηρού",
    BoundingBox: ["39.1302655", "39.4068457", "20.7125839", "20.9923524"],
  },
  {
    Municipality: "Δήμος Ζίτσας",
    BoundingBox: ["39.5376757", "39.8423038", "20.4780644", "20.8375957"],
  },
  {
    Municipality: "Δήμος Ζωγράφου",
    BoundingBox: ["37.9641607", "37.9846896", "23.7566796", "23.8231995"],
  },
  {
    Municipality: "Δήμος Ηγουμενίτσας",
    BoundingBox: ["39.2720104", "39.5760007", "20.1420153", "20.5510494"],
  },
  {
    Municipality: "Δήμος Ήλιδας",
    BoundingBox: ["37.7275636", "37.9193012", "21.258557", "21.6635835"],
  },
  {
    Municipality: "Δήμος Ηλιούπολης",
    BoundingBox: ["37.9158133", "37.950047", "23.7404228", "23.8068096"],
  },
  {
    Municipality: "Δήμος Ηράκλειας",
    BoundingBox: ["40.9445419", "41.2519651", "23.1263188", "23.4979816"],
  },
  {
    Municipality: "Δήμος Ηρωικής Νήσου Κάσου ",
    BoundingBox: '["35.339442","35.4350691","26.843939","27.0148924"]',
  },
  {
    Municipality: "Δήμος Ηρωικής Νήσου Ψαρών",
    BoundingBox: ["38.5195593", "38.6102989", "25.496071", "25.6233845"],
  },
  {
    Municipality: "Δήμος Ηρωικής Πόλεως Νάουσας ",
    BoundingBox: ["40.602484", "40.659025", "22.029476", "22.109814"],
  },
  {
    Municipality: "Δήμος Θάσου",
    BoundingBox: ["40.555021", "40.8326415", "24.5112195", "24.7865041"],
  },
  {
    Municipality: "Δήμος Θερμαϊκού",
    BoundingBox: ["40.350164", "40.513063", "22.812917", "23.0404877"],
  },
  {
    Municipality: "Δήμος Θέρμης",
    BoundingBox: ["40.3669506", "40.5872182", "22.9224662", "23.2518359"],
  },
  {
    Municipality: "Δήμος Θέρμου",
    BoundingBox: ["38.4460797", "38.7200718", "21.5791559", "21.8269873"],
  },
  {
    Municipality: "Δήμος Θηβαίων",
    BoundingBox: ["38.1679609", "38.4727437", "22.8656141", "23.4828968"],
  },
  {
    Municipality: "Δήμος Θήρας",
    BoundingBox: ["36.2208507", "36.482206", "25.1956402", "25.4878919"],
  },
  {
    Municipality: "Δήμος Ιάσμου",
    BoundingBox: ["40.9845481", "41.2647085", "25.0462172", "25.4102227"],
  },
  {
    Municipality: "Δήμος Ιεράπετρας",
    BoundingBox: ["34.8652251", "35.160227", "25.4715528", "25.9873256"],
  },
  {
    Municipality: "Δήμος Ιεράς Πόλεως Μεσολογγίου",
    BoundingBox: ["38.2905517", "38.5644891", "21.0896059", "21.6041156"],
  },
  {
    Municipality: "Δήμος Ιητών",
    BoundingBox: ["36.6188744", "36.7975066", "25.2437329", "25.6955327"],
  },
  {
    Municipality: "Δήμος Ιθάκης",
    BoundingBox: ["38.2824021", "38.5678647", "20.6146483", "21.1198626"],
  },
  {
    Municipality: "Δήμος Ικαρίας",
    BoundingBox: ["37.5086914", "37.6911906", "25.97381", "26.3664785"],
  },
  {
    Municipality: "Δήμος Ιλίου",
    BoundingBox: ["38.0156242", "38.0603189", "23.6805858", "23.7287199"],
  },
  {
    Municipality: "Δήμος Ιστιαίας - Αιδηψού",
    BoundingBox: ["38.8072409", "39.0430731", "22.8114899", "23.3917634"],
  },
  {
    Municipality: "Δήμος Καβάλας",
    BoundingBox: ["40.8988478", "41.1395899", "24.2377281", "24.5487772"],
  },
  {
    Municipality: "Δήμος Καισαριανής",
    BoundingBox: ["37.9437066", "37.9735671", "23.7500072", "23.8220631"],
  },
  {
    Municipality: "Δήμος Καλαβρύτων",
    BoundingBox: ["37.7807215", "38.1491736", "21.8113183", "22.264355"],
  },
  {
    Municipality: "Δήμος Καλαμαριάς",
    BoundingBox: ["40.5599027", "40.5929368", "22.9350428", "22.9757144"],
  },
  {
    Municipality: "Δήμος Καλαμάτας",
    BoundingBox: ["36.9665074", "37.2292075", "21.9827859", "22.3025925"],
  },
  {
    Municipality: "Δήμος Καλλιθέας",
    BoundingBox: ["37.9354888", "37.9645972", "23.6811579", "23.7203152"],
  },
  {
    Municipality: "Δήμος Καλυμνίων",
    BoundingBox: ["36.8843363", "37.08732", "26.8806796", "27.1787207"],
  },
  {
    Municipality: "Δήμος Καντάνου - Σελίνου",
    BoundingBox: ["35.2210234", "35.4004703", "23.5422292", "23.9092324"],
  },
  {
    Municipality: "Δήμος Καρδίτσας",
    BoundingBox: ["39.1020077", "39.4657924", "21.6892716", "22.0531265"],
  },
  {
    Municipality: "Δήμος Καρπάθου",
    BoundingBox: ["35.3965494", "35.910436", "26.2217171", "27.2426063"],
  },
  {
    Municipality: "Δήμος Καρπενησίου",
    BoundingBox: ["38.6749866", "39.1547877", "21.5486788", "21.9463528"],
  },
  {
    Municipality: "Δήμος Καρύστου",
    BoundingBox: ["37.9311537", "38.3020447", "24.0955513", "24.601422"],
  },
  {
    Municipality: "Δήμος Κασσάνδρας",
    BoundingBox: ["39.9075862", "40.1466658", "23.3040652", "23.7502254"],
  },
  {
    Municipality: "Δήμος Καστοριάς",
    BoundingBox: ["40.4023857", "40.7522034", "21.0124869", "21.5174639"],
  },
  {
    Municipality: "Δήμος Κατερίνης",
    BoundingBox: ["40.1017682", "40.4039542", "22.1033851", "22.6424803"],
  },
  {
    Municipality: "Δήμος Κάτω Νευροκοπίου",
    BoundingBox: ["41.2136452", "41.5504792", "23.6235476", "24.2158568"],
  },
  {
    Municipality: "Δήμος Κέας",
    BoundingBox: ["37.5217342", "37.7572619", "24.1007487", "24.4106822"],
  },
  {
    Municipality: "Δήμος Κεντρικών Τζουμέρκων",
    BoundingBox: ["39.2371485", "39.5400258", "21.0055789", "21.4058562"],
  },
  {
    Municipality: "Δήμος Κερατσινίου - Δραπετσώνας",
    BoundingBox: ["37.9385724", "37.9941791", "23.5929002", "23.6355556"],
  },
  {
    Municipality: "Δήμος Κέρκυρας",
    BoundingBox: ["38.0455437", "38.0468856", "23.7311901", "23.7330202"],
  },
  {
    Municipality: "Δήμος Κεφαλλονιάς",
    BoundingBox: ["38.0868552", "38.0880817", "23.7010557", "23.7033389"],
  },
  {
    Municipality: "Δήμος Κηφισιάς",
    BoundingBox: ["38.0604788", "38.1225421", "23.7676418", "23.8844978"],
  },
  {
    Municipality: "Δήμος Κιλελέρ",
    BoundingBox: ["39.3878085", "39.7071296", "22.221643", "22.7938603"],
  },
  {
    Municipality: "Δήμος Κιλκίς",
    BoundingBox: ["40.7903482", "41.3441483", "22.6448704", "23.2531851"],
  },
  {
    Municipality: "Δήμος Κιμώλου",
    BoundingBox: ["36.7431228", "36.8467776", "24.5193069", "24.6662031"],
  },
  {
    Municipality: "Δήμος Κισσάμου",
    BoundingBox: ["35.2659036", "35.6480154", "23.4737553", "23.7417127"],
  },
  {
    Municipality: "Δήμος Κοζάνης",
    BoundingBox: ["40.0914657", "40.4886543", "21.5939368", "22.1547848"],
  },
  {
    Municipality: "Δήμος Κομοτηνής",
    BoundingBox: ["40.9223581", "41.3194669", "25.1233306", "25.6131122"],
  },
  {
    Municipality: "Δήμος Κόνιτσας",
    BoundingBox: ["39.9551587", "40.376603", "20.5498648", "21.085426"],
  },
  {
    Municipality: "Δήμος Κορδελιού-Ευόσμου",
    BoundingBox: ["40.6566316", "40.6977725", "22.8660972", "22.9315378"],
  },
  {
    Municipality: "Δήμος Κορινθίων",
    BoundingBox: ["37.7019617", "37.955092", "22.7342871", "23.1795175"],
  },
  {
    Municipality: "Δήμος Κορυδαλλού",
    BoundingBox: ["37.970557", "38.0036854", "23.6249957", "23.6648962"],
  },
  {
    Municipality: "Δήμος Κρωπίας",
    BoundingBox: ["37.8016099", "37.9480108", "23.790843", "23.9325419"],
  },
  {
    Municipality: "Δήμος Κυθήρων",
    BoundingBox: ["35.8223957", "36.3857798", "22.8910148", "23.3294964"],
  },
  {
    Municipality: "Δήμος Κύθνου",
    BoundingBox: ["37.2991918", "37.4832535", "24.3616732", "24.5309819"],
  },
  {
    Municipality: "Δήμος Κύμης-Αλιβερίου",
    BoundingBox: ["38.2501129", "38.6873234", "23.9129269", "24.2524028"],
  },
  {
    Municipality: "Δήμος Κω",
    BoundingBox: ["36.6716869", "36.9156672", "26.9171931", "27.3559917"],
  },
  {
    Municipality: "Δήμος Λαγκαδά",
    BoundingBox: ["40.4859938", "41.0060349", "22.9536032", "23.4568168"],
  },
  {
    Municipality: "Δήμος Λαμιέων",
    BoundingBox: ["38.7124738", "39.0553462", "22.1072187", "22.5976192"],
  },
  {
    Municipality: "Δήμος Λαυρεωτικής",
    BoundingBox: ["37.4608352", "37.8572705", "23.8966465", "24.0859434"],
  },
  {
    Municipality: "Δήμος Λεβαδέων",
    BoundingBox: ["38.2226397", "38.5958615", "22.6409397", "23.0262612"],
  },
  {
    Municipality: "Δήμος Λειψών",
    BoundingBox: ["37.2222036", "37.344161", "26.6959029", "26.8147"],
  },
  {
    Municipality: "Δήμος Λέρου",
    BoundingBox: ["36.8991884", "37.307164", "26.1621253", "27.103567"],
  },
  {
    Municipality: "Δήμος Λέσβου",
    BoundingBox: ["38.0052341", "38.005531", "23.7379515", "23.7407116"],
  },
  {
    Municipality: "Δήμος Λευκάδας",
    BoundingBox: ["38.5407294", "38.8524129", "20.5366249", "20.9613028"],
  },
  {
    Municipality: "Δήμος Λήμνου",
    BoundingBox: ["39.7817004", "40.036039", "25.0331782", "25.4472239"],
  },
  {
    Municipality: "Δήμος Λίμνης Πλαστήρα",
    BoundingBox: ["39.2248409", "39.3770391", "21.6210016", "21.8371303"],
  },
  {
    Municipality: "Δήμος Λοκρών",
    BoundingBox: ["38.5005311", "38.7587701", "22.8565803", "23.3782159"],
  },
  {
    Municipality: "Δήμος Λουτρακίου-Αγίων Θεοδώρων",
    BoundingBox: ["37.8929341", "38.1252683", "22.8490695", "23.1789667"],
  },
  {
    Municipality: "Δήμος Λυκόβρυσης-Πεύκης",
    BoundingBox: ["38.0495071", "38.07833", "23.7727888", "23.8021523"],
  },
  {
    Municipality: "Δήμος Μαλεβιζίου",
    BoundingBox: ["35.187334", "35.431999", "24.8746259", "25.1010385"],
  },
  {
    Municipality: "Δήμος Μάνδρας-Ειδυλλίας",
    BoundingBox: ["38.0349575", "38.2689717", "23.124131", "23.5297919"],
  },
  {
    Municipality: "Δήμος Μαντουδίου-Λίμνης-Αγίας Άννας",
    BoundingBox: ["38.6756744", "38.960144", "23.1965608", "23.725375"],
  },
  {
    Municipality: "Δήμος Μαραθώνος",
    BoundingBox: ["38.0355088", "38.2884436", "23.9017188", "24.1170712"],
  },
  {
    Municipality: "Δήμος Μαρκόπουλου Μεσογαίας",
    BoundingBox: ["37.8244992", "37.9352704", "23.892219", "24.0573029"],
  },
  {
    Municipality: "Δήμος Μαρωνείας-Σαπών",
    BoundingBox: ["40.8572938", "41.1581043", "25.3146021", "25.9184536"],
  },
  {
    Municipality: "Δήμος Μεγαλόπολης",
    BoundingBox: ["37.1364252", "37.5670942", "21.9462713", "22.2848384"],
  },
  {
    Municipality: "Δήμος Μεγανησίου",
    BoundingBox: ["38.5806534", "38.720422", "20.7311805", "20.8113445"],
  },
  {
    Municipality: "Δήμος Μεγαρέων",
    BoundingBox: ["37.9515125", "38.1144795", "23.1066861", "23.4842506"],
  },
  {
    Municipality: "Δήμος Μεγίστης",
    BoundingBox: ["36.1068459", "36.1663988", "29.4862873", "29.6451961"],
  },
  {
    Municipality: "Δήμος Μεσσήνης",
    BoundingBox: ["36.8365644", "37.2249754", "21.7528171", "22.0357901"],
  },
  {
    Municipality: "Δήμος Μεταμορφώσεως",
    BoundingBox: ["38.0503943", "38.0798999", "23.7452681", "23.7848298"],
  },
  {
    Municipality: "Δήμος Μετεώρων",
    BoundingBox: ["39.5443438", "39.9006667", "21.1204205", "22.0279286"],
  },
  {
    Municipality: "Δήμος Μετσόβου",
    BoundingBox: ["39.6603036", "39.8893114", "20.9720593", "21.2964913"],
  },
  {
    Municipality: "Δήμος Μήλου",
    BoundingBox: ["36.548069", "36.8060631", "24.1408034", "24.5477163"],
  },
  {
    Municipality: "Δήμος Μινώα Πεδιάδας",
    BoundingBox: ["35.0371", "35.2371", "25.19745", "25.39745"],
  },
  {
    Municipality: "Δήμος Μονεμβασιάς",
    BoundingBox: ["36.4280089", "37.0479767", "22.7862343", "23.2021385"],
  },
  {
    Municipality: "Δήμος Μοσχάτου-Ταύρου",
    BoundingBox: ["37.937163", "37.9784388", "23.6694517", "23.7063088"],
  },
  {
    Municipality: "Δήμος Μουζακίου",
    BoundingBox: ["39.3120641", "39.5138471", "21.5324605", "21.9085173"],
  },
  {
    Municipality: "Δήμος Μύκης",
    BoundingBox: ["41.1666775", "41.4078727", "24.7349045", "25.2255603"],
  },
  {
    Municipality: "Δήμος Μυκόνου",
    BoundingBox: ["37.3672137", "37.5005521", "25.1982948", "25.5789994"],
  },
  {
    Municipality: "Δήμος Μυλοποτάμου",
    BoundingBox: ["35.2192109", "35.4273268", "24.6437331", "24.9319489"],
  },
  {
    Municipality: "Δήμος Μώλου-Αγίου Κωνσταντίνου",
    BoundingBox: ["38.6804685", "38.8518488", "22.5364541", "22.9304812"],
  },
  {
    Municipality: "Δήμος Νάξου & Μικρών Κυκλάδων",
    BoundingBox: ["37.984472", "37.9872358", "23.8479468", "23.8479786"],
  },
  {
    Municipality: "Δήμος Ναυπακτίας",
    BoundingBox: ["38.3058556", "38.7834483", "21.5147066", "22.0263638"],
  },
  {
    Municipality: "Δήμος Ναυπλιέων",
    BoundingBox: ["37.4293581", "37.724587", "22.7718924", "23.1428377"],
  },
  {
    Municipality: "Δήμος Νεάπολης-Συκεών",
    BoundingBox: ["40.6302344", "40.6725442", "22.9344825", "23.0096074"],
  },
  {
    Municipality: "Δήμος Νέας Ζίχνης",
    BoundingBox: ["40.882771", "41.1347207", "23.7224757", "24.0385202"],
  },
  {
    Municipality: "Δήμος Νέας Ιωνίας",
    BoundingBox: ["38.0279209", "38.0508005", "23.7414402", "23.7822725"],
  },
  {
    Municipality: "Δήμος Νέας Προποντίδας",
    BoundingBox: ["40.1204724", "40.4312588", "23.0049442", "23.366208"],
  },
  {
    Municipality: "Δήμος Νέας Σμύρνης",
    BoundingBox: ["37.9303674", "37.9553134", "23.699496", "23.7287696"],
  },
  {
    Municipality: "Δήμος Νέας Φιλαδέλφειας - Νέας Χαλκηδόνας",
    BoundingBox: ["38.0164081", "38.0591344", "23.7193639", "23.7500856"],
  },
  {
    Municipality: "Δήμος Νεμέας",
    BoundingBox: ["37.7633706", "37.9019046", "22.5314189", "22.7896672"],
  },
  {
    Municipality: "Δήμος Νεστόριου",
    BoundingBox: ["40.1427544", "40.5663324", "20.7753739", "21.1368758"],
  },
  {
    Municipality: "Δήμος Νέστου",
    BoundingBox: ["40.8448097", "41.2067935", "24.4717869", "24.8041428"],
  },
  {
    Municipality: "Δήμος Νίκαιας - Αγίου Ιωάννη Ρέντη",
    BoundingBox: ["37.9528632", "37.9965364", "23.6103102", "23.6910969"],
  },
  {
    Municipality: "Δήμος Νικόλαου Σκουφά",
    BoundingBox: ["39.0066512", "39.2756345", "20.954673", "21.1332427"],
  },
  {
    Municipality: "Δήμος Νισύρου",
    BoundingBox: ["36.4965598", "36.6832244", "26.9599065", "27.214454"],
  },
  {
    Municipality: "Δήμος Νότιας Κυνουρίας",
    BoundingBox: ["36.9934448", "37.3152589", "22.6209146", "22.9851568"],
  },
  {
    Municipality: "Δήμος Νοτίου Πηλίου",
    BoundingBox: ["39.0837307", "39.3818425", "23.0464632", "23.3512679"],
  },
  {
    Municipality: "Δήμος Ξάνθης",
    BoundingBox: ["41.0588707", "41.3585045", "24.4600498", "25.1000213"],
  },
  {
    Municipality: "Δήμος Ξηρομέρου",
    BoundingBox: ["38.4463907", "38.7622872", "20.8947215", "21.2710173"],
  },
  {
    Municipality: "Δήμος Ξυλοκάστρου-Ευρωστίνης",
    BoundingBox: ["37.9036254", "38.1430061", "22.354324", "22.7050237"],
  },
  {
    Municipality: "Δήμος Οινουσσών",
    BoundingBox: ["38.4853035", "38.5446963", "26.1825564", "26.2961"],
  },
  {
    Municipality: "Δήμος Οιχαλίας",
    BoundingBox: ["37.1627208", "37.485153", "21.8221962", "22.1001786"],
  },
  {
    Municipality: "Δήμος Ορεστιάδας",
    BoundingBox: ["41.4275441", "41.7488889", "26.0603654", "26.6139185"],
  },
  {
    Municipality: "Δήμος Ορεστίδος",
    BoundingBox: ["40.311473", "40.4990227", "21.0356142", "21.4917375"],
  },
  {
    Municipality: "Δήμος Οροπεδίου Λασιθίου",
    BoundingBox: ["35.0881492", "35.2290643", "25.3990268", "25.5778841"],
  },
  {
    Municipality: "Δήμος Ορχομενού",
    BoundingBox: ["38.3968941", "38.5859365", "22.8652725", "23.3782159"],
  },
  {
    Municipality: "Δήμος Παγγαίου",
    BoundingBox: ["40.7178488", "41.0060903", "23.8599576", "24.35776"],
  },
  {
    Municipality: "Δήμος Παιανίας",
    BoundingBox: ["37.9119315", "38.0116564", "23.8143692", "23.9477993"],
  },
  {
    Municipality: "Δήμος Παιονίας",
    BoundingBox: ["40.8383395", "41.1862919", "22.2502153", "22.716739"],
  },
  {
    Municipality: "Δήμος Παλαιού Φαλήρου",
    BoundingBox: ["37.9172443", "37.9433638", "23.6785538", "23.7241038"],
  },
  {
    Municipality: "Δήμος Παλαμά",
    BoundingBox: ["39.3659049", "39.5569702", "21.8470667", "22.2643302"],
  },
  {
    Municipality: "Δήμος Παλλήνης",
    BoundingBox: ["37.976616", "38.0435795", "23.8415655", "23.9271771"],
  },
  {
    Municipality: "Δήμος Παξών",
    BoundingBox: ["39.1285022", "39.241072", "20.1252109", "20.2567531"],
  },
  {
    Municipality: "Δήμος Παπάγου-Χολαργού",
    BoundingBox: ["37.9733312", "38.0088652", "23.7820862", "23.8264945"],
  },
  {
    Municipality: "Δήμος Παρανεστίου",
    BoundingBox: ["41.1073217", "41.5687475", "24.2254381", "24.7841594"],
  },
  {
    Municipality: "Δήμος Πάργας",
    BoundingBox: ["39.1705758", "39.3572134", "20.3008345", "20.697799"],
  },
  {
    Municipality: "Δήμος Πάρου",
    BoundingBox: ["36.9676799", "37.1946337", "25.0602409", "25.2991201"],
  },
  {
    Municipality: "Δήμος Πάτμου",
    BoundingBox: ["37.2651948", "37.4144069", "26.4431991", "26.7703326"],
  },
  {
    Municipality: "Δήμος Παύλου Μελά",
    BoundingBox: ["40.6544747", "40.7153563", "22.919315", "22.9848868"],
  },
  {
    Municipality: "Δήμος Πέλλας",
    BoundingBox: ["40.6398406", "40.9707525", "22.197384", "22.6449322"],
  },
  {
    Municipality: "Δήμος Πεντέλης",
    BoundingBox: ["38.0339321", "38.0794617", "23.8266229", "23.9649802"],
  },
  {
    Municipality: "Δήμος Περάματος",
    BoundingBox: ["37.9515616", "37.9951719", "23.5518216", "23.6086563"],
  },
  {
    Municipality: "Δήμος Πετρούπολης",
    BoundingBox: ["38.0303351", "38.0585698", "23.6521441", "23.6981855"],
  },
  {
    Municipality: "Δήμος Πηνειού",
    BoundingBox: ["37.7950273", "37.9322162", "21.1050727", "21.4166508"],
  },
  {
    Municipality: "Δήμος Πλατανιά",
    BoundingBox: ["35.2941525", "35.6957793", "23.7059423", "23.9864712"],
  },
  {
    Municipality: "Δήμος Πολύγυρου",
    BoundingBox: ["40.2253151", "40.5874444", "23.1577539", "23.6949387"],
  },
  {
    Municipality: "Δήμος Πόρου",
    BoundingBox: ["37.412119", "37.5770171", "23.4218327", "23.5474656"],
  },
  {
    Municipality: "Δήμος Πρέβεζας",
    BoundingBox: ["38.9444292", "39.340405", "20.5479071", "20.9253835"],
  },
  {
    Municipality: "Δήμος Πρεσπών",
    BoundingBox: ["40.6036812", "40.8838538", "20.9560258", "21.27934"],
  },
  {
    Municipality: "Δήμος Προσοτσάνης",
    BoundingBox: ["41.0120593", "41.3069721", "23.7205494", "24.1186808"],
  },
  {
    Municipality: "Δήμος Πύδνας-Κολινδρού",
    BoundingBox: ["40.3361801", "40.5497099", "22.3146108", "22.6678848"],
  },
  {
    Municipality: "Δήμος Πυλαίας-Χορτιάτη",
    BoundingBox: ["40.5351045", "40.7090238", "22.9684346", "23.1653324"],
  },
  {
    Municipality: "Δήμος Πύλης",
    BoundingBox: ["39.3558178", "39.5916197", "21.1810318", "21.7555563"],
  },
  {
    Municipality: "Δήμος Πύλου - Νέστορος",
    BoundingBox: ["36.6719904", "37.1459708", "21.6477329", "21.96767"],
  },
  {
    Municipality: "Δήμος Πύργου",
    BoundingBox: ["37.5407459", "37.8351584", "21.2986726", "21.696119"],
  },
  {
    Municipality: "Δήμος Πωγωνίου",
    BoundingBox: ["39.7555309", "40.0828209", "20.3117877", "20.71456"],
  },
  {
    Municipality: "Δήμος Ραφήνας-Πικερμίου",
    BoundingBox: ["37.9955779", "38.0456704", "23.8919481", "24.0261262"],
  },
  {
    Municipality: "Δήμος Ρεθύμνης",
    BoundingBox: ["35.2329995", "35.4130209", "24.2701958", "24.7071309"],
  },
  {
    Municipality: "Δήμος Ρήγα Φεραίου",
    BoundingBox: ["38.0103747", "38.0105862", "23.8468816", "23.8489108"],
  },
  {
    Municipality: "Δήμος Σαλαμίνας",
    BoundingBox: ["37.8731564", "38.0033179", "23.3912929", "23.5792569"],
  },
  {
    Municipality: "Δήμος Σαμοθράκης",
    BoundingBox: ["40.3922151", "40.5102951", "25.4433882", "25.8388756"],
  },
  {
    Municipality: "Δήμος Σάμου",
    BoundingBox: ["37.7979444", "37.7981539", "26.7012276", "26.7015086"],
  },
  {
    Municipality: "Δήμος Σαρωνικού",
    BoundingBox: ["37.6654295", "37.8677437", "23.8619327", "24.0442268"],
  },
  {
    Municipality: "Δήμος Σερβίων-Βελβεντού",
    BoundingBox: ["40.2154134", "40.2155478", "21.9815827", "21.9819896"],
  },
  {
    Municipality: "Δήμος Σερίφου",
    BoundingBox: ["37.1085661", "37.2605802", "24.4124647", "24.6126735"],
  },
  {
    Municipality: "Δήμος Σερρών",
    BoundingBox: ["40.9565693", "41.316459", "23.299923", "23.7355793"],
  },
  {
    Municipality: "Δήμος Σητείας",
    BoundingBox: ["34.9170837", "35.3837601", "25.851666", "26.3527912"],
  },
  {
    Municipality: "Δήμος Σιθωνίας",
    BoundingBox: ["39.9363259", "40.3586398", "23.5961321", "24.0227"],
  },
  {
    Municipality: "Δήμος Σικίνου",
    BoundingBox: ["36.622499", "36.7176015", "25.0064185", "25.1837854"],
  },
  {
    Municipality: "Δήμος Σικυωνίων",
    BoundingBox: ["37.7976907", "38.0458935", "22.2184584", "22.7725588"],
  },
  {
    Municipality: "Δήμος Σιντικής",
    BoundingBox: ["41.1231999", "41.4065869", "22.8687607", "23.6570131"],
  },
  {
    Municipality: "Δήμος Σίφνου",
    BoundingBox: ["36.8968697", "37.0416501", "24.6348242", "24.7650881"],
  },
  {
    Municipality: "Δήμος Σκιάθου",
    BoundingBox: ["39.1151188", "39.2143211", "23.3865743", "23.5313609"],
  },
  {
    Municipality: "Δήμος Σκοπέλου",
    BoundingBox: ["39.0688698", "39.211439", "23.5891577", "23.8054153"],
  },
  {
    Municipality: "Δήμος Σκύδρας",
    BoundingBox: ["40.697233", "40.9234522", "22.0756052", "22.2559472"],
  },
  {
    Municipality: "Δήμος Σκύρου",
    BoundingBox: ["38.7469555", "39.0213139", "24.3419354", "24.6860697"],
  },
  {
    Municipality: "Δήμος Σουλίου",
    BoundingBox: ["39.2879108", "39.6549575", "20.3557009", "20.6829291"],
  },
  {
    Municipality: "Δήμος Σουφλίου",
    BoundingBox: ["40.9695304", "41.3890861", "25.8940253", "26.4700871"],
  },
  {
    Municipality: "Δήμος Σοφάδων",
    BoundingBox: ["39.024264", "39.449649", "21.9067355", "22.2540724"],
  },
  {
    Municipality: "Δήμος Σπάρτης",
    BoundingBox: ["36.8540808", "37.3358026", "22.2207465", "22.6536202"],
  },
  {
    Municipality: "Δήμος Σπάτων-Αρτέμιδος",
    BoundingBox: ["37.9268332", "38.0100071", "23.8823039", "24.0372599"],
  },
  {
    Municipality: "Δήμος Σπετσών",
    BoundingBox: ["36.7696695", "37.2840934", "23.0798951", "23.8984122"],
  },
  {
    Municipality: "Δήμος Στυλίδας",
    BoundingBox: ["38.8669093", "39.0550036", "22.5155066", "23.0178638"],
  },
  {
    Municipality: "Δήμος Σύμης",
    BoundingBox: ["36.5137171", "36.6681614", "27.7451035", "27.8810257"],
  },
  {
    Municipality: "Δήμος Σύρου - Ερμούπολης",
    BoundingBox: ["37.362391", "37.6342012", "24.650042", "25.060304"],
  },
  {
    Municipality: "Δήμος Σφακίων",
    BoundingBox: ["35.1746581", "35.356175", "23.8667959", "24.3046125"],
  },
  {
    Municipality: "Δήμος Τανάγρας",
    BoundingBox: ["38.1350794", "38.3966092", "23.3698973", "23.6909081"],
  },
  {
    Municipality: "Δήμος Τεμπών",
    BoundingBox: ["39.6634587", "40.0003701", "22.3958539", "22.7247419"],
  },
  {
    Municipality: "Δήμος Τήλου",
    BoundingBox: ["36.3582488", "36.4852856", "27.2818781", "27.470768"],
  },
  {
    Municipality: "Δήμος Τήνου",
    BoundingBox: ["37.5247655", "37.6782396", "24.9660893", "25.2542706"],
  },
  {
    Municipality: "Δήμος Τοπείρου",
    BoundingBox: ["40.8458664", "41.1693712", "24.6685508", "24.9254531"],
  },
  {
    Municipality: "Δήμος Τρικκαίων",
    BoundingBox: ["39.4500001", "39.7692328", "21.5392894", "21.9866435"],
  },
  {
    Municipality: "Δήμος Τρίπολης",
    BoundingBox: ["37.2120076", "37.8406336", "22.1496212", "22.5991848"],
  },
  {
    Municipality: "Δήμος Τριφυλίας",
    BoundingBox: ["36.9925711", "37.3981516", "21.5383917", "21.8588952"],
  },
  {
    Municipality: "Δήμος Τροιζηνίας",
    BoundingBox: ["37.957499", "37.9579848", "23.6469814", "23.6475236"],
  },
  {
    Municipality: "Δήμος Τυρνάβου",
    BoundingBox: ["39.6083787", "39.9343343", "22.1039625", "22.4572581"],
  },
  {
    Municipality: "Δήμος Ύδρας",
    BoundingBox: ["37.2511465", "37.3712307", "23.2737539", "23.5794446"],
  },
  {
    Municipality: "Δήμος Φαιστού",
    BoundingBox: ["34.9212109", "35.223663", "24.7213904", "24.9492512"],
  },
  {
    Municipality: "Δήμος Φαρκαδόνος",
    BoundingBox: ["39.6338238", "39.6356343", "22.388147", "22.3884611"],
  },
  {
    Municipality: "Δήμος Φαρσάλων",
    BoundingBox: ["39.1304589", "39.4251315", "22.2127801", "22.669078"],
  },
  {
    Municipality: "Δήμος Φιλιατών",
    BoundingBox: ["39.5150999", "39.8167094", "20.0085205", "20.5309099"],
  },
  {
    Municipality: "Δήμος Φιλοθέης-Ψυχικού",
    BoundingBox: ["37.998108", "38.0333692", "23.7611151", "23.7923604"],
  },
  {
    Municipality: "Δήμος Φλώρινας",
    BoundingBox: ["40.6318221", "40.9434565", "21.2547953", "21.830504"],
  },
  {
    Municipality: "Δήμος Φολεγάνδρου",
    BoundingBox: ["36.5925101", "36.6670852", "24.8369182", "24.99199"],
  },
  {
    Municipality: "Δήμος Φούρνων Κορσεών",
    BoundingBox: ["37.5034669", "37.6557763", "26.3959988", "26.5690576"],
  },
  {
    Municipality: "Δήμος Φυλής",
    BoundingBox: ["38.0461503", "38.2113174", "23.6086304", "23.731006"],
  },
  {
    Municipality: "Δήμος Χαϊδαρίου",
    BoundingBox: ["37.9893665", "38.0365051", "23.5568673", "23.6747459"],
  },
  {
    Municipality: "Δήμος Χαλανδρίου",
    BoundingBox: ["38.0031539", "38.0464033", "23.7800697", "23.8575417"],
  },
  {
    Municipality: "Δήμος Χαλκηδόνος",
    BoundingBox: ["37.9843415", "37.985755", "23.7612982", "23.7643853"],
  },
  {
    Municipality: "Δήμος Χάλκης",
    BoundingBox: ["36.1981424", "36.2861477", "27.5157137", "27.7247282"],
  },
  {
    Municipality: "Δήμος Χαλκιδέων",
    BoundingBox: ["38.3488315", "38.5535849", "23.2693906", "23.7991291"],
  },
  {
    Municipality: "Δήμος Χερσονήσου",
    BoundingBox: ["35.2013407", "35.4669568", "25.1836565", "25.5121475"],
  },
  {
    Municipality: "Δήμος Χίου",
    BoundingBox: ["38.1248206", "38.6046515", "25.2886863", "26.1631665"],
  },
  {
    Municipality: "Δήμος Ωραιοκάστρου",
    BoundingBox: ["40.6900126", "40.8239243", "22.788907", "23.0289183"],
  },
  {
    Municipality: "Δήμος Ωρωπού",
    BoundingBox: ["38.1506387", "38.3410128", "23.6321333", "23.9361491"],
  },
];
