import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
// eslint-disable-next-line
import App, { UnderMaintenance } from "./App";

render(
  <BrowserRouter>
    <App />
    {/* <UnderMaintenance /> */}
  </BrowserRouter>,
  document.getElementById("root")
);
