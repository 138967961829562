import "./about.css";
import "./header.css";
import "./map.css";
import "./under_maintenance.css";
import "fontsource-roboto";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import EmailIcon from "@material-ui/icons/Email";
import GitHubIcon from "@material-ui/icons/GitHub";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import Link from "@material-ui/core/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Slider from "@material-ui/core/Slider";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import firebase from "firebase/app";
import greekMunicipalities from "./greekMunicipalities.js";
import mapStyles from "./mapStyles";
import moment from "moment";
import { isMobile } from "react-device-detect";

import {
  ThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs

// Add the Firebase products that you want to use
require("firebase/app");
require("firebase/auth");
require("firebase/firestore");

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: "geolocating-news-291914.firebaseapp.com",
  databaseURL: "https://geolocating-news-291914.firebaseio.com",
  projectId: "geolocating-news-291914",
  storageBucket: "geolocating-news-291914.appspot.com",
  messagingSenderId: "100734094858",
  appId: "1:100734094858:web:b801c661505fe8601419f3",
  measurementId: "G-SQV840K5E7",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var db = firebase.firestore();

//
// CUSTOMIZATION OF CARD
const cardContentCustomTheme = createMuiTheme({
  CardContent: {
    root: {
      padding: "30px",
    },
  },
});

//
// MAP SETTINGS
const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};
const center = {
  lat: 38.072811,
  lng: 23.81193,
};
const GREECE_BOUNDS = {
  north: 43,
  south: 34,
  west: 18,
  east: 30,
};
const restriction = {
  latLngBounds: GREECE_BOUNDS,
  strictBounds: false,
};

const is_mobile = isMobile === true ? true : false;

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  maxZoom: 13,
  restriction: restriction,
  clickableIcons: false,
  zoomControl: is_mobile === true ? false : true,
};

//
// NOTIFICATIONS AUDIO
const audio = new Audio("/media/popup_tone.mp3");

//
// SLIDER SETUP
const useStyles = makeStyles({
  root: {
    color: "#666666",
  },
});
const marks = [
  {
    value: 0,
    label: "LIVE",
  },
  {
    value: -2,
    label: "-2h",
  },
  {
    value: -4,
    label: "-4h",
  },
  {
    value: -6,
    label: "-6h",
  },
  {
    value: -8,
    label: "-8h",
  },
  {
    value: -10,
    label: "-10h",
  },
  {
    value: -12,
    label: "-12h",
  },
];
function valueLabelFormat(value) {
  return marks[marks.findIndex((mark) => mark.value === value)].label;
}

var displayedArticles = [];
var selectedTimeValue = 2;
const live_time = moment()
  .utcOffset("GMT+02:00")
  .format("YYYY-MM-DD HH:mm:ss.SSSSSS");
var interval = false;

export const UpdateAnalytics = (action) => {
  ReactGA.event({
    category: "Button Click",
    action: action,
  });
};

export const getArticles = async () => {
  const articles = [];

  var minimum_time;

  if (selectedTimeValue === 0) {
    minimum_time = live_time;
  } else {
    minimum_time = moment()
      .subtract(selectedTimeValue, "hours")
      .format("YYYY-MM-DD HH:mm:ss.SSSSSS");
  }

  const snapshot = await db
    .collection("articles")
    .where("published_time", ">=", minimum_time)
    .get();
  snapshot.forEach((doc) => articles.push(doc.data()));

  return articles;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//
// DEFAULT FUNCTION APP
export default function App() {
  const [value, setValue] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  ReactGA.initialize("UA-181246592-1");
  ReactGA.pageview("Default Page");

  const [markers, setMarkers] = useState([]);

  //
  // Slider Variables Setup
  const [label, setLabel] = useState("-2h");
  const handleSliderChange = (event, newValue) => {
    setLabel(valueLabelFormat(newValue));
    selectedTimeValue = newValue * -1;
    // if (newValue === 0) {
    //   // Live
    //   live_time = moment().format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    // }
  };

  const classes = useStyles();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  //
  // Map Variables Setup
  const [selected, setSelected] = useState(null);
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(13);
  }, []);

  //
  // Location Search Methods
  const [selectedLocation, clearSelectedLocation] = useState(null);
  const blurTextField = (e) => {
    document.activeElement.blur();
  };
  const submitLocation = (e, v) => {
    if (e.target !== undefined) {
      e.target.blur();
    }
    e.preventDefault();
    try {
      if (v) {
        var curr_bounds = v["BoundingBox"];
        var southWest = new window.google.maps.LatLng({
          lat: parseFloat(curr_bounds[0]),
          lng: parseFloat(curr_bounds[2]),
        });
        var northEast = new window.google.maps.LatLng({
          lat: parseFloat(curr_bounds[1]),
          lng: parseFloat(curr_bounds[3]),
        });
        curr_bounds = new window.google.maps.LatLngBounds(southWest, northEast);
        mapRef.current.fitBounds(curr_bounds, 0);
        mapRef.current.panToBounds(curr_bounds, 0);
        mapRef.current.setCenter(curr_bounds.getCenter());

        blurTextField();
      }
    } catch {}
  };

  //
  // About Page Flag Setup
  const [showAbout, setAbout] = useState(false);

  const openAbout = (e) => {
    if (e.target !== undefined) {
      e.target.blur();
    }
    setAbout(true);
  };

  const closeAbout = (e) => {
    if (e.target !== undefined) {
      e.target.blur();
    }
    setAbout(false);
  };

  //
  // About Notification Flag Setup
  const [checked, setChecked] = useState(false);
  const ToggleNotification = (e) => {
    if (e.target !== undefined) {
      e.target.blur();
    }

    setChecked((prev) => !prev);
  };

  //
  // Markers on Map Setup

  useEffect(() => {
    const updateMarkers = () => {
      getArticles().then(function (articles) {
        // checking if a previously added article is now removed. If so, we remove from displayedArticles
        for (let index = displayedArticles.length - 1; index >= 0; index--) {
          if (
            !articles.some(
              (curr_article) => curr_article.pk === displayedArticles[index].pk
            )
          ) {
            setMarkers((current) => [
              ...current.filter(
                (curr_marker) => curr_marker.pk !== displayedArticles[index].pk
              ),
            ]);
            displayedArticles.splice(index, 1);
          }
        }

        // checking if a previously added article is already showed. If so, we avoid adding it again on displayedArticles
        articles.forEach(function (item, index) {
          if (
            !displayedArticles.some(
              (curr_article) => curr_article["pk"] === item.pk
            )
          ) {
            let new_article = {
              pk: item.pk,
              title: item.title,
              url: item.url,
              page: item.page,
              published_time: item.published_time,
              formatted_time: item.formatted_time,
              coords_list: item.coords_list,
              showed: false,
              x: moment(),
            };
            displayedArticles.push(new_article);
          }
        });

        // inserting our the new data from displayedArticles to markers set, so they can be displayed on the map
        displayedArticles.forEach(function (item, index) {
          if (!item.showed) {
            let coords = item.coords_list.split(","); // each articled record has its coordinates set in a list that we have to separe it
            let coords_list_number = 0; // to create a unique key for each marker
            coords.forEach(function (item_coords, index) {
              let lat = item_coords.split("-")[0];
              let lng = item_coords.split("-")[1];
              if (checked) {
                audio.play();
              }

              setMarkers((current) => [
                ...current,
                {
                  pk: item.pk,
                  key: item.pk * 10 + ++coords_list_number,
                  title: item.title,
                  url: item.url,
                  page: item.page,
                  published_time: item.published_time,
                  formatted_time: item.formatted_time,
                  lat: parseFloat(lat),
                  lng: parseFloat(lng),
                },
              ]);
              item.showed = true;
            });
          }
        });
      });
    };
    clearInterval(interval);
    updateMarkers();
    interval = setInterval(updateMarkers, 60000);
  }, [label]); // eslint-disable-line react-hooks/exhaustive-deps

  //
  // Map Load Messages Handling
  if (loadError) return "Error Loading App";
  if (!isLoaded) return "";

  //
  // Returning
  return (
    <div>
      {/***************/}
      {/* Header Page */}
      <div className="header">
        <div className="logo">
          <img src="/media/gn_logo.png" alt="Logo" onClick={closeAbout} />
        </div>
        <div className="motoContainer">
          <Typography
            variant="subtitle2"
            style={{ marginBottom: "0" }}
            display="block"
            className="moto"
            gutterBottom
          >
            Οπτικοποίηση των πιο πρόσφατων ειδήσεων στον Χάρτη της Ελλάδας
          </Typography>
        </div>
      </div>

      {/*************/}
      {/* Body Page */}
      <div>
        {/*************/}
        {/* About Page */}
        {showAbout && (
          <div className="about_page">
            <div className="about_content">
              {/* About Page Button */}
              <div className="closePageContainer">
                <IconButton
                  className="buttonContainer_iconContainer"
                  disableRipple={true}
                  title="Κλείσιμο"
                  onClick={closeAbout}
                >
                  <CloseIcon className="buttonContainer_icon" />
                </IconButton>
              </div>
              {/* Description Text*/}
              <div
                className="box"
                style={{ paddingTop: is_mobile === true ? "100px" : "60px" }}
              >
                <Box sx={{ width: "75%" }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{ style: { background: "#e45340" } }}
                    >
                      <Tab label="Η ΕΦΑΡΜΟΓΗ" {...a11yProps(0)} />
                      <Tab label="ΠΗΓΕΣ ΕΙΔΗΣΕΩΝ" {...a11yProps(1)} />
                      <Tab label="ΑΝΑΦΟΡΕΣ" {...a11yProps(2)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <Typography variant="subtitle1" gutterBottom id="tab">
                      To Geolocating News προσφέρει στον χρήστη έναν νέο,
                      διαφορετικό τρόπο ενημέρωσης για τις πιο πρόσφατες
                      εξελίξεις στην χώρα μας. Με την χρήση ενός χάρτη,
                      οπτικοποιεί και παρουσιάζει άμεσα τις τελευταίες ειδήσεις
                      στο σημείο που συμβαίνουν, την στιγμή που συμβαίνουν. Η
                      εφαρμογή παρακολουθεί μερικές από τις βασικότερες
                      ενημερωτικές ιστοσελίδες στον ελληνικό χώρο, εμφανίζοντας
                      απευθείας την αντίστοιχη ειδοποίηση για ένα γεγονός, με το
                      που υπάρξει η πρώτη δημοσιογραφική κάλυψη. <br></br>
                      <br></br>Η παρούσα εφαρμογή αποτελεί τελικό προϊόν
                      πτυχιακής εργασίας και υλοποιήθηκε κατά το ακαδημαϊκό έτος
                      2019-2020 στα πλαίσια του προγράμματος σπουδών του
                      Τμήματος Πληροφορικής και Τηλεπικοινωνιών του Εθνικού και
                      Καποδιστριακού Πανεπιστήμιου Αθηνών από τον φοιτητή Νικόλα
                      Σταυρακάκη με επιβλέπουσα καθηγήτρια την Επίκουρη
                      Καθηγήτρια Μαρία Ρούσσου. Μπορείτε να βρείτε το κείμενο
                      της εργασίας{" "}
                      <a
                        href="https://pergamos.lib.uoa.gr/uoa/dl/frontend/el/browse/2925881"
                        target="_blank"
                        rel="noopener noreferrer"
                        id="thesis_link"
                        onClick={() => UpdateAnalytics("Thesis Clicked")}
                      >
                        εδώ
                      </a>
                      .
                    </Typography>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      id="tab"
                      style={{ textAlign: "center" }}
                    >
                      <div className="media_container">
                        <a
                          href="https://www.in.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/in_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.protothema.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/protothema_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>
                        </a>
                        <a
                          href="https://www.cnn.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/cnn_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>
                        <a
                          href="https://atnews.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/atnews_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>
                        </a>

                        <a
                          href="https://www.epirusnow.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/epirusnow_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://www.stereanews.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/stereanews_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="http://evros24.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/evros24_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://www.argolikeseidhseis.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/argolikeseidhseis_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://e-thessalia.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/ethessalia_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://www.makthes.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/makthes_logo.png"
                            className="media_logo"
                            id="media_logo_makthes"
                            height="35"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://www.neakriti.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/neakriti_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://odelalis.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/odelalis_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>

                        <a
                          href="https://www.pna.gr/"
                          id="no_style"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="/media/media_logos/pna_logo.png"
                            className="media_logo"
                            alt=""
                          ></img>{" "}
                        </a>
                      </div>
                    </Typography>{" "}
                    <Typography
                      variant="subtitle2"
                      id="tab"
                      style={{ width: "50%", textAlign: "center" }}
                    >
                      Ευθύνη για κάθε λανθασμένη αντιστοίχιση μεταξύ είδησης &
                      περιοχής φέρει ο παρών ιστότοπος και μόνο, και όχι οι
                      πηγές των ειδήσεων.
                    </Typography>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="mentions_list">
                      <Card className="mention">
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="textSecondary"
                            gutterBottom
                          >
                            31 Αυγούστου 2021
                          </Typography>
                          <Typography variant="h5" align="left" gutterBottom>
                            To “ATnews.gr” εντάχθηκε στην Eπαναστατική εφαρμογή
                            “Geolocating News”
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            gutterBottom
                            color="textSecondary"
                          >
                            ATnews
                          </Typography>
                          <Typography variant="body2" align="justify">
                            Με μεγάλη μας χαρά ενημερωθήκαμε απο τις πρώτες
                            πρωινές ώρες της Τρίτης 31 Αυγούστου πως το
                            ATnews.gr εχει ενταχθεί στην ΝΕΑ Eπαναστατική
                            εφαρμογή Αμεσης Ενημέρωση του Νικόλα Σταυρακάκη
                            “Geolocating News”, μαζί με μεγάλες σε
                            επισκεψιμότητα Ελληνικές Ιστοσελίδες. Πρόκειται για
                            μια σπουδαία εφαρμογή που σε πραγματικό χρονο...
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            target="_blank"
                            href="https://atnews.gr/to-atnews-gr-%CE%B5%CE%BD%CF%84%CE%AC%CF%87%CE%B8%CE%B7%CE%BA%CE%B5-%CF%83%CF%84%CE%B7%CE%BD-e%CF%80%CE%B1%CE%BD%CE%B1%CF%83%CF%84%CE%B1%CF%84%CE%B9%CE%BA%CE%AE-%CE%B5%CF%86%CE%B1%CF%81%CE%BC/"
                          >
                            ΔΕΙΤΕ ΤΟ ΑΡΘΡΟ
                          </Button>
                        </CardActions>
                      </Card>
                      <Card className="mention">
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="textSecondary"
                            gutterBottom
                          >
                            28 Αυγούστου 2021
                          </Typography>
                          <Typography variant="h5" align="left" gutterBottom>
                            Στην εφαρμογή Geolocating News ο «Ντελάλης»!
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            gutterBottom
                            color="textSecondary"
                          >
                            ο Ντελάλης
                          </Typography>
                          <Typography variant="body2" align="justify">
                            Στην εφαρμογή Geolocating News, η οποία προσφέρει
                            οπτικοποίηση των πιο πρόσφατων ειδήσεων και
                            εξελίξεων μέσα από έναν χάρτη, συμμετέχει ο
                            «Ντελάλης»! Πρόκειται για έναν νέο, διαφορετικό
                            τρόπο ενημέρωσης για τις πιο πρόσφατες εξελίξεις στη
                            χώρα μας σε πραγματικό χρόνο. To Geolocating News
                            αποτελεί μια έξυπνη και πρακτική ιδέα...
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            target="_blank"
                            href="https://odelalis.gr/stin-efarmogi-geolocating-news-o-ntelalis/"
                          >
                            ΔΕΙΤΕ ΤΟ ΑΡΘΡΟ
                          </Button>
                        </CardActions>
                      </Card>
                      <Card className="mention">
                        <CardContent>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color="textSecondary"
                            gutterBottom
                          >
                            28 Ιουνίου 2021
                          </Typography>
                          <Typography variant="h5" align="left" gutterBottom>
                            Geolocating News - Οπτικοποίηση των ειδήσεων στην
                            Ελλάδα μέσα από έναν χάρτη
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            gutterBottom
                            color="textSecondary"
                          >
                            dwrean.net
                          </Typography>
                          <Typography variant="body2" align="justify">
                            To Geolocating News είναι μια εκπληκτική ιδέα που
                            υλοποιήθηκε στα πλαίσια πτυχιακής εργασίας από τον
                            φοιτητή Νικόλα Σταυρακάκη και προσφέρει έναν νέο,
                            διαφορετικό τρόπο ενημέρωσης για τις πιο πρόσφατες
                            εξελίξεις στη χώρα μας. Με την χρήση ενός χάρτη,
                            οπτικοποιεί και παρουσιάζει...
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            target="_blank"
                            href="https://www.dwrean.net/2021/06/geolocating-news.html"
                          >
                            ΔΕΙΤΕ ΤΟ ΑΡΘΡΟ
                          </Button>
                        </CardActions>
                      </Card>
                    </div>
                  </TabPanel>
                </Box>
              </div>
              {/* Sources Images */}
            </div>

            {/* Copyright Text*/}
            <footer className="about_footer" style={{ marginTop: "20px" }}>
              <div className="my_profiles">
                <Tooltip title="Visit my LinkedIn Profile!">
                  <Button
                    target="_blank"
                    href="https://linkedin.com/in/nickst97/"
                    onClick={() => UpdateAnalytics("LinkedIn Clicked")}
                    disableElevation={true}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="text"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <LinkedInIcon />
                  </Button>
                </Tooltip>
                <Tooltip title="Visit my GitHub Profile!">
                  <Button
                    target="_blank"
                    href="https://github.com/nickst97"
                    onClick={() => UpdateAnalytics("Github Clicked")}
                    disableElevation={true}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="text"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <GitHubIcon></GitHubIcon>
                  </Button>
                </Tooltip>
                <Tooltip title="Send me an email!">
                  <Button
                    target="_blank"
                    href="mailto:nickst97@outlook.com"
                    onClick={() => UpdateAnalytics("Mail Clicked")}
                    alt="ss"
                    disableElevation={true}
                    disableRipple={true}
                    disableFocusRipple={true}
                    variant="text"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <EmailIcon alt="sssss"></EmailIcon>
                  </Button>
                </Tooltip>
              </div>
              <Typography variant="caption" gutterBottom>
                © 2021 Nick Stavrakakis Some Rights Reserved
              </Typography>
            </footer>
          </div>
        )}
      </div>

      {/*************/}
      {/* Body Page */}
      <div>
        {/**************/}
        {/* Search Bar */}
        <div className="sb">
          <Paper className="sbroot">
            {" "}
            <Autocomplete
              options={greekMunicipalities}
              fullWidth
              autoComplete
              getOptionLabel={(option) =>
                (option ? option.Municipality : "") || ""
              }
              freeSolo
              autoSelect
              value={selectedLocation || {}}
              onChange={submitLocation}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{ ...params.InputProps, disableUnderline: true }}
                  fullWidth
                  placeholder=" Επιλογή Δήμου"
                  type="text"
                />
              )}
            />
            <Divider className="sbdivider" orientation="vertical" />
            <IconButton
              color="primary"
              className="sbiconButton"
              disableRipple={true}
              title="Τρέχουσα Τοποθεσία"
              onClick={(e) => {
                clearSelectedLocation();
                if (e.target !== undefined) {
                  e.target.blur();
                }
                navigator.geolocation.getCurrentPosition(
                  (position) => {
                    panTo({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude,
                    });
                  },
                  () => null,
                  { timeout: 10000 }
                );
              }}
            >
              <GpsFixedIcon className="sbGPSiconButton" />
            </IconButton>
          </Paper>{" "}
        </div>

        {/****************/}
        {/* Side Buttons */}
        <div className="buttonContainer">
          {/* About Page Button */}
          <div className="infoContainer">
            <IconButton
              className="buttonContainer_iconContainer"
              disableRipple={true}
              title="Πληροφορίες"
              onClick={openAbout}
            >
              <InfoIcon className="buttonContainer_icon" />
            </IconButton>
          </div>

          {/* Time Slider */}
          <div className="sliderContainer">
            <Typography
              variant="caption"
              className="label"
              style={{ color: label === "LIVE" ? "#e45340" : "#666666" }}
            >
              <strong>{label}</strong>
            </Typography>
            <div className={"sliderObject"}>
              <Slider
                orientation="vertical"
                defaultValue={-2}
                aria-labelledby="discrete-slider-small-steps"
                step={-2}
                min={-12}
                max={0}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChangeCommitted={handleSliderChange}
                valueLabelDisplay="auto"
                className={classes.root}
              />
            </div>
          </div>

          {/* Notification Off Button */}
          <div className="soundContainer">
            <IconButton
              className="buttonContainer_iconContainer"
              disableRipple={true}
              onClick={ToggleNotification}
              title="Ήχος Ειδοποίησης"
            >
              {checked ? (
                <NotificationsActiveIcon className="buttonContainer_icon" />
              ) : (
                <NotificationsOffIcon className="buttonContainer_icon" />
              )}
            </IconButton>
          </div>
        </div>

        {/* MAP */}

        {/**************/}
        {/* Map Object */}
        <div className="map">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={8}
            options={options}
            onLoad={onMapLoad}
            onClick={(e) => {
              if (e.target !== undefined) {
                e.target.blur();
              }
              blurTextField();
              setSelected(null);
            }}
          >
            {markers.map((marker) => (
              <Marker
                animation={2}
                pk={marker.pk}
                key={marker.key}
                title={marker.title}
                url={marker.url}
                page={marker.page}
                published_time={marker.published_time}
                formatted_time={marker.formatted_time}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url:
                    // marker.published_time > entry_time
                    marker.published_time > live_time
                      ? "/media/gn_marker_new.png"
                      : "/media/gn_marker.png",
                  scaledSize: new window.google.maps.Size(31, 31),
                }}
                onClick={(e) => {
                  if (e.target !== undefined) {
                    e.target.blur();
                  }
                  setSelected(marker);
                }}
              />
            ))}
            {selected ? (
              <InfoWindow
                position={{ lat: selected.lat, lng: selected.lng }}
                options={{ maxWidth: 200 }}
                onCloseClick={() => {
                  setSelected(null);
                }}
              >
                <Card elevation={0}>
                  <ThemeProvider theme={cardContentCustomTheme}>
                    <Typography variant="subtitle2" align="left">
                      <Link
                        target="_blank"
                        href={selected.url}
                        rel="noreferrer"
                        color="inherit"
                      >
                        {selected.title}
                      </Link>
                    </Typography>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      align="left"
                    >
                      {selected.page}
                      {} - {}
                      {selected.formatted_time}
                    </Typography>
                  </ThemeProvider>
                </Card>
              </InfoWindow>
            ) : null}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}

export function UnderMaintenance() {
  ReactGA.initialize("UA-181246592-1");
  ReactGA.pageview("Default Page");
  return (
    <div className="bg">
      <img
        src="/media/under_maintenance_tr.png"
        alt="The site is under maintenance"
      ></img>
    </div>
  );
}
